import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import Buttons from '../../components/form/buttons'

import LogoIcon from '../../images/logo_icon_3.svg'
import LogoText from '../../images/logo_4.png'
import LogoVisa from '../../images/visa.svg'
// import LogoMasterCard from '../../images/mastercard.svg'
import LogoBankBay from '../../images/icon-bay-bank.svg'
import LogoBankBBL from '../../images/icon-bbl-bank.svg'
import LogoBankKTB from '../../images/icon-ktb-bank.svg'
import LogoBankSCB from '../../images/icon-scb-bank.svg'
import ImgOptionsActive from '../../images/options-active.svg'
import ImgOptionsUnActive from '../../images/options-unactive.svg'

import authActions from '../../state/auth/actions'
import paymentActions from '../../state/payment/actions'
import { convertTransactionOrderType, GetCardType } from '../../utils/string'
import queryString from 'query-string'
import { notificationWarning } from '../../utils/notification'

function handle(props, params) {
  const locale = props.app.locale[props.app.lang]

  const [isLoading, setLoading] = useState(true)
  const [paymentType, setPaymentType] = useState('credit_card') // [credit_card,internet_banking]
  const [cardType, setCardType] = useState('Visa')

  const [cardNo1, setCardNo1] = useState('')
  const [cardNo2, setCardNo2] = useState('')
  const [cardNo3, setCardNo3] = useState('')
  const [cardNo4, setCardNo4] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardExpireMonth, setCardExpireMonth] = useState('01')
  const [cardExpireYear, setCardExpireYear] = useState('2019')
  const [cardCVV, setCardCVV] = useState('')

  const [internet_banking_type, setSelectedBank] = useState(
    'internet_banking_bay'
  ) // [internet_banking_bay,internet_banking_bbl,internet_banking_ktb,internet_banking_scb]
  const listBank = [
    {
      id: 'internet_banking_bay',
      title: 'ธนาคารกรุงศรีอยุธยา (KrungsriOnline)',
      company: 'ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)',
      img: LogoBankBay,
    },
    {
      id: 'internet_banking_ktb',
      title: 'ธนาคารกรุงไทย (KTB Netbank)',
      company: 'ธนาคารกรุงไทย จำกัด (มหาชน)',
      img: LogoBankKTB,
    },
    {
      id: 'internet_banking_bbl',
      title: 'ธนาคารกรุงเทพ (Bualuang iBanking)',
      company: 'ธนาคารกรุงเทพ จำกัด (มหาชน)',
      img: LogoBankBBL,
    },
    {
      id: 'internet_banking_scb',
      title: 'ธนาคารไทยพาณิชย์ (SCBEasy)',
      company: 'ธนาคารไทยพาณิชย์ จำกัด (มหาชน)',
      img: LogoBankSCB,
    },
  ]
  useEffect(function() {
    props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        if (params.type === 'buy_ticket' && !props.state.buyTicketDetail) {
          navigate('/event-detail/?id=' + params.event_id)
        }

        if (params.join_type === 'free') {
          let payload = { payment_method: 'none' }
          props.onAutoSubmit({
            card_no: '',
            cardInformation: null,
            order_type: params.type,
            package_id: params.package_id,
            event_id: params.event_id || '0',
            amount: 0,
            currency: 'thb',
            payload,
          })
        } else {
          props.onLoad({
            order_type: params.type,
            event_id: params.event_id,
            package_id: params.package_id,
            callBack: () => setLoading(false),
          })
        }
      },
    })
  }, [])

  function onChangeCard1(e) {
    if (e.currentTarget.validity.valid) {
      setCardNo1(e.currentTarget.value)
      setCardType(GetCardType(e.currentTarget.value))

      if (e.currentTarget.value.length >= 4) {
        document.getElementById('txt_card_2').focus()
      }
    }
  }

  function onChangeCard2(e) {
    if (e.currentTarget.validity.valid) {
      setCardNo2(e.currentTarget.value)
      setCardType(GetCardType(e.currentTarget.value))

      if (e.currentTarget.value.length >= 4) {
        document.getElementById('txt_card_3').focus()
      }
    }
  }

  function onChangeCard3(e) {
    if (e.currentTarget.validity.valid) {
      setCardNo3(e.currentTarget.value)
      setCardType(GetCardType(e.currentTarget.value))

      if (e.currentTarget.value.length >= 4) {
        document.getElementById('txt_card_4').focus()
      }
    }
  }

  function onChangeCard4(e) {
    if (e.currentTarget.validity.valid) {
      setCardNo4(e.currentTarget.value)
      setCardType(GetCardType(e.currentTarget.value))

      if (e.currentTarget.value.length >= 4) {
        document.getElementById('txt_card_name').focus()
      }
    }
  }

  function onChangeCardName(e) {
    setCardName(e.currentTarget.value)
  }

  function onChangeExpireMonth(e) {
    setCardExpireMonth(e.currentTarget.value)
  }

  function onChangeExpireYear(e) {
    setCardExpireYear(e.currentTarget.value)
  }

  function onChangeCVV(e) {
    if (e.currentTarget.validity.valid) {
      setCardCVV(e.currentTarget.value)
    }
  }

  function onSubmit() {
    let payload = {
      payment_method: 'none',
    }
    if (paymentType === 'credit_card') {
      payload = {
        payment_method: paymentType,
        charge_parameters: {
          charge_token: '',
        },
      }
    } else if (paymentType === 'internet_banking') {
      payload = {
        payment_method: paymentType,
        charge_parameters: {
          internet_banking_type: internet_banking_type,
        },
      }
    }

    const amount =
      params.type === 'buy_ticket'
        ? props.state.createOrderStatus.data.amount
        : props.state.createOrderStatus.data.amount
    if (parseFloat(amount) >= 20) {
      props.onPaymentSubmit({
        card_no: [cardNo1, cardNo2, cardNo3, cardNo4].join('-'),
        cardInformation: {
          name: cardName,
          number: [cardNo1, cardNo2, cardNo3, cardNo4].join(''),
          expiration_month: cardExpireMonth,
          expiration_year: cardExpireYear,
          security_code: cardCVV,
        },
        order_type: params.type,
        package_id: params.package_id,
        event_id: params.event_id || '0',
        currency:
          params.type === 'buy_ticket'
            ? props.state.buyTicketDetail.currency
            : props.state.paymentDetail.currency,
        payload,
      })
    } else {
      notificationWarning(locale.WARNING, locale.WARNING_MIN_PAYMENT)
    }
  }

  return {
    isLoading,
    orderType: convertTransactionOrderType(params.type, locale),
    orderLogo: props.state.paymentDetail.logo,
    orderTitle:
      params.type === 'buy_package'
        ? `New Event ( ${props.state.paymentDetail.packageName} )`
        : params.type === 'buy_ticket'
        ? props.state.paymentDetail.name
        : 'Upgrade',
    price: props.home.buyPackageDetail.finalAmount,
    currency: props.state.paymentDetail.currency.toUpperCase(),
    paymentType,
    cardType,
    cardNo1,
    cardNo2,
    cardNo3,
    cardNo4,
    cardName,
    cardExpireMonth,
    cardExpireYear,
    cardCVV,
    listBank,
    internet_banking_type,
    onChangeCard1,
    onChangeCard2,
    onChangeCard3,
    onChangeCard4,
    onChangeCardName,
    onChangeExpireMonth,
    onChangeExpireYear,
    onChangeCVV,
    setPaymentType,
    setSelectedBank,
    onSubmit,
  }
}

const Input = {
  CardNo1: function({ value, onChange }) {
    return (
      <input
        type="text"
        id="txt_card_1"
        className="__textbox"
        value={value}
        placeholder=""
        pattern="[0-9]*"
        onChange={onChange}
        style={{ width: 55 }}
      />
    )
  },
  CardNo2: function({ value, onChange }) {
    return (
      <input
        type="text"
        id="txt_card_2"
        className="__textbox"
        value={value}
        placeholder=""
        pattern="[0-9]*"
        onChange={onChange}
        style={{ width: 55, marginLeft: 10 }}
      />
    )
  },
  CardNo3: function({ value, onChange }) {
    return (
      <input
        type="text"
        id="txt_card_3"
        className="__textbox"
        value={value}
        placeholder=""
        pattern="[0-9]*"
        onChange={onChange}
        style={{ width: 55, marginLeft: 10 }}
      />
    )
  },
  CardNo4: function({ value, onChange }) {
    return (
      <input
        type="text"
        id="txt_card_4"
        className="__textbox"
        value={value}
        placeholder=""
        pattern="[0-9]*"
        onChange={onChange}
        style={{ width: 55, marginLeft: 10 }}
      />
    )
  },
  CardName: function({ value, onChange }) {
    return (
      <React.Fragment>
        <h3 style={{ marginBottom: 10 }}>CARDHOLDER NAME</h3>
        <input
          type="text"
          id="txt_card_name"
          className="__textbox"
          value={value}
          placeholder="Name"
          onChange={onChange}
          style={{ width: '100%' }}
        />
      </React.Fragment>
    )
  },
  ExpireMonth: function({ value, onChange }) {
    return (
      <input
        type="text"
        className="__textbox"
        value={value}
        placeholder="Month"
        pattern="[0-9]*"
        maxLength="2"
        style={{ width: '45%' }}
        onChange={onChange}
      />
    )
  },
  ExpireYear: function({ value, onChange }) {
    return (
      <input
        type="text"
        className="__textbox"
        value={value}
        placeholder="Year"
        maxLength="4"
        onChange={onChange}
        style={{ width: '45%', marginLeft: 10 }}
      />
    )
  },
  CardCVV: function({ value, onChange }) {
    return (
      <React.Fragment>
        <h3 style={{ marginBottom: 10 }}>CVV</h3>
        <input
          type="text"
          className="__textbox"
          value={value}
          placeholder="CVV"
          pattern="[0-9]*"
          maxLength="3"
          onChange={onChange}
          style={{ width: '100%' }}
        />
      </React.Fragment>
    )
  },
  BankingType: function({ value, onChange }) {
    return (
      <input
        type="text"
        className="__textbox"
        value={value}
        placeholder="Banking Type"
        onChange={onChange}
      />
    )
  },
  TabCreditCard: function({ isActive, onClick }) {
    return (
      <button
        className={`__btn-tab ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        CREDIT CARD
      </button>
    )
  },
  TabInternetBanking: function({ isActive, onClick }) {
    return (
      <button
        className={`__btn-tab ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        INTERNET BANKING
      </button>
    )
  },
}

/**
 * @return {null}
 */
function PaymentPage(props) {
  const params = queryString.parse(props.location.search)

  if (!params.package_id || !params.type) {
    return null
  }

  if (
    params.package_id === '0' ||
    params.package_id === '' ||
    params.type === ''
  ) {
    return null
  }

  if (
    ['buy_package', 'upgrade_package', 'buy_ticket'].indexOf(params.type) <= -1
  ) {
    return null
  }

  const {
    CardNo1,
    CardNo2,
    CardNo3,
    CardNo4,
    CardName,
    ExpireMonth,
    ExpireYear,
    CardCVV,
    TabCreditCard,
    TabInternetBanking,
  } = Input //BankingType,
  const {
    isLoading,
    // orderType,
    // orderLogo,
    orderTitle,
    price,
    currency,
    paymentType,
    // cardType,
    cardNo1,
    cardNo2,
    cardNo3,
    cardNo4,
    cardName,
    cardExpireMonth,
    cardExpireYear,
    cardCVV,
    internet_banking_type,
    listBank,
    onChangeCard1,
    onChangeCard2,
    onChangeCard3,
    onChangeCard4,
    onChangeCardName,
    onChangeExpireMonth,
    onChangeExpireYear,
    onChangeCVV,
    setPaymentType,
    setSelectedBank,
    onSubmit,
  } = handle(props, params)

  if (isLoading) {
    return <Loading />
  }

  if (params.type === 'buy_ticket' && !props.state.buyTicketDetail) {
    return <Loading />
  }

  return (
    <Layout auth={props.auth.currentUser !== null}>
      <SEO keywords={[`invitree`]} />
      <div className="box-payment">
        <div className="__header">
          <div className="__header-logo">
            <img src={LogoIcon} alt="" />
          </div>
          <div className="__header-text">
            <img src={LogoText} alt="" style={{ marginBottom: 10 }} />
            <h2 className="text-bold text-right" style={{ marginBottom: 5 }}>
              {orderTitle}
            </h2>
            <h2
              className="text-red text-right text-bold"
              style={{ marginBottom: 10 }}
            >
              {params.type === 'buy_ticket'
                ? props.state.createOrderStatus.data.amount
                : props.state.createOrderStatus.data.amount}
              <small style={{ marginLeft: 5 }}>
                {params.type === 'buy_ticket'
                  ? props.state.buyTicketDetail.currency
                  : currency}
              </small>
            </h2>
          </div>
        </div>
        <div className="__body">
          <div className="__tab" style={{ marginBottom: 20 }}>
            <TabCreditCard
              isActive={paymentType === 'credit_card'}
              onClick={() => {
                setPaymentType('credit_card')
              }}
            />
            {/* disable InternetBanking
            <TabInternetBanking
              isActive={paymentType === 'internet_banking'}
              onClick={() => {
                setPaymentType('internet_banking')
              }}
            />
            disable InternetBanking */}
          </div>
          {paymentType === 'credit_card' && (
            <React.Fragment>
              <h3 style={{ marginBottom: 10 }}>CARD NUMBER</h3>
              <div style={{ display: 'flex', marginBottom: 10 }}>
                <div style={{ flex: 1 }}>
                  <CardNo1 value={cardNo1} onChange={onChangeCard1} />
                  <CardNo2 value={cardNo2} onChange={onChangeCard2} />
                  <CardNo3 value={cardNo3} onChange={onChangeCard3} />
                  <CardNo4 value={cardNo4} onChange={onChangeCard4} />
                </div>
                <div style={{ flexBasis: 80 }}>
                  <img
                    src={LogoVisa}
                    alt=""
                    className="visa-logo-img"
                    style={{ height: 35 }}
                  />
                </div>
              </div>

              <CardName value={cardName} onChange={onChangeCardName} />
              <div style={{ display: 'flex', marginTop: 10 }}>
                <div style={{ flex: 1 }}>
                  <h3 style={{ marginBottom: 10 }}>EXPIRE DATE</h3>
                  <ExpireMonth
                    value={cardExpireMonth}
                    onChange={onChangeExpireMonth}
                  />
                  <ExpireYear
                    value={cardExpireYear}
                    onChange={onChangeExpireYear}
                  />
                </div>
                <div style={{ flexBasis: 80 }}>
                  <CardCVV value={cardCVV} onChange={onChangeCVV} />
                </div>
              </div>
            </React.Fragment>
          )}
          {paymentType === 'internet_banking' && (
            <table className="payment-internet-banking">
              <tbody>
                {listBank.map((v, i) => (
                  <tr
                    key={`payment-internet-banking-row-${i}`}
                    style={{ marginTop: 20 }}
                  >
                    <td style={{ padding: '5px 3px' }}>
                      {internet_banking_type === v.id ? (
                        <img
                          src={ImgOptionsActive}
                          alt=""
                          style={{ width: '100%', cursor: 'pointer' }}
                        />
                      ) : (
                        <img
                          src={ImgOptionsUnActive}
                          alt=""
                          style={{ width: '100%', cursor: 'pointer' }}
                          onClick={() => setSelectedBank(v.id)}
                        />
                      )}
                    </td>
                    <td style={{ padding: '5px 0px' }}>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flexBasis: 40, padding: 3 }}>
                          <img src={v.img} alt="" style={{ width: '100%' }} />
                        </div>
                        <div style={{ flex: 1, paddingLeft: 5 }}>
                          <h4>{v.title}</h4>
                          <small>{v.company}</small>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div style={{ marginTop: 20, width: '100%' }}>
            <Buttons.Button4 onClick={onSubmit} style={{ width: '100%' }}>
              PAY SECURE
            </Buttons.Button4>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.payment,
    home: state.home
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onPaymentSubmit: paymentActions.onPaymentSubmit,
      onLoad: paymentActions.onPaymentLoad,
      onAutoSubmit: paymentActions.onAutoSubmit,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPage)

/*
<JsonTree data={
  {
    title: 'Payment',
    auth: { ...props.auth },
    redux: { ...props.state },

    orderType,
    orderLogo,
    orderTitle,
    price,

    isLoading,
    paymentType,
    cardType,
    cardNo1,
    cardNo2,
    cardNo3,
    cardNo4,
    cardName,
    cardExpireMonth,
    cardExpireYear,
    cardCVV,
    listBank,
    internet_banking_type,

  }}/>
*/
